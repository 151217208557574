const responsiveObj = {
  largeDesktop: {
    breakpoint: { max: 6000, min: 1381 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1380, min: 901 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 900, min: 577 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

export { responsiveObj as default };
