import React, { FC, useRef, FormEvent, ChangeEvent } from 'react';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Loading from 'components/common/Loading';
import IconCustom from 'components/common/IconCustom';
import { LocationSearchProps } from './models';
import './LocationSearch.scss';

const LocationSearch: FC<LocationSearchProps> = ({
  title,
  intro,
  useLocationCTALabel,
  onUseYourLocationClick,
  onAddressSubmit,
  status,
  address,
  onAddressChange,
  onSearchInputReset,
  googleSearchButtonAriaLabel,
  buttonCloseAriaLabel,
}) => {
  const inputId = `location-search-${title}`;
  const isLoading = status.value === 'loading';
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onAddressChange(value);
  };

  const onResetClick = () => {
    onSearchInputReset();
    inputRef?.current?.focus();
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (address.trim().length) {
      onAddressSubmit(address);
    }
  };

  return (
    <div className="location-search" data-testid="location-search">
      <DangerouslySetInnerHtml html={intro} className="location-search__intro" />
      <form onSubmit={onSubmit} data-testid="location-search-form">
        <label className="location-search__label" htmlFor={inputId}>
          {title}
        </label>
        <div className="location-search__form-wrapper">
          <input
            aria-label={title}
            ref={inputRef}
            type="search"
            id={inputId}
            className="location-search__input"
            onChange={onChange}
            disabled={isLoading}
            value={address}
          />
          {address ? (
            <button
              onClick={onResetClick}
              aria-label={buttonCloseAriaLabel}
              className="location-search__reset"
              type="button"
              disabled={isLoading}
            >
              <IconCustom icon="x_donts_icon" />
            </button>
          ) : null}
          <button
            aria-label={googleSearchButtonAriaLabel}
            className="location-search__submit"
            type="submit"
            disabled={isLoading}
          >
            {status.value === 'loading' ? (
              <Loading className="location-search__loading" />
            ) : (
              <IconCustom icon="search_icon-1" />
            )}
          </button>
        </div>
      </form>
      {status.value === 'error' ? (
        <div className="location-search__error">{status.message}</div>
      ) : null}
      <button
        type="button"
        aria-label={useLocationCTALabel}
        className="location-search__use-location"
        onClick={onUseYourLocationClick}
        disabled={isLoading}
      >
        <IconCustom icon="your-location location-search__icon-location" />
        {useLocationCTALabel}
      </button>
    </div>
  );
};
export default LocationSearch;
