import UniversalImage from 'components/common/UniversalImage';
import React, { FC } from 'react';
import uniqBy from 'lodash/uniqBy';
import { IPromotionProductCardsProps } from './model';

import './PromotionProductCards.scss';

const PromotionProductCards: FC<IPromotionProductCardsProps> = ({
  promotionProductCards,
  salsifyProducts,
  salsifyProductsVariants,
}) => {
  const { title, productCardsItem, anchorId } = promotionProductCards[0];

  return (
    <div className="anchor" id={anchorId}>
      <div className="promotion-product-cards" data-testid="promotion-product-cards">
        <div className="promotion-product-cards__title">{title}</div>
        <div className="promotion-product-cards__items">
          {productCardsItem.map(
            ({
              sku,
              productFeature,
              featureImage,
              featureImageAlt,
              secondaryFeature,
              secondaryFeatureImage,
              secondaryFeatureImageAlt,
              pdfBrochure,
              pdfBrochureFile: { fallbackUrl },
            }) => {
              const filteredSalsifyProductsVariants = salsifyProductsVariants.map(
                ({ productVariants }) => {
                  const product = productVariants?.find(
                    ({ properties: { variantSku } }) => variantSku === sku
                  );

                  if (product === undefined) return;

                  return {
                    defaultProductTitle: product.properties.productTitle,
                    defaultProductImage: product.properties.image,
                    sku: product.properties.variantSku,
                  };
                }
              );

              const filteredSalsifyProducts = salsifyProducts.filter(
                ({ sku: salsifySku }) => salsifySku === sku
              );

              const allFilteredSku = uniqBy(
                [...filteredSalsifyProducts, ...filteredSalsifyProductsVariants],
                'sku'
              ).filter((item) => !!item);

              return allFilteredSku.map((props) => {
                const { defaultProductImage, defaultProductTitle } = props!;

                return (
                  <div className="promotion-product-card" key={defaultProductTitle}>
                    <div className="promotion-product-card__content">
                      <div className="promotion-product-card__image-wrapper">
                        <UniversalImage
                          imageAlt={defaultProductTitle}
                          img={defaultProductImage}
                          wrapperClassName="promotion-product-card__image"
                        />
                      </div>
                      <div className="promotion-product-card__description">
                        <div className="promotion-product-card__subtitle">
                          <strong>{defaultProductTitle}</strong>
                        </div>
                        {featureImage && productFeature ? (
                          <div className="promotion-product-card__features">
                            <UniversalImage
                              wrapperClassName="promotion-product-card__icon"
                              img={featureImage}
                              imageAlt={featureImageAlt}
                            />
                            <span>{productFeature}</span>
                          </div>
                        ) : null}
                        {secondaryFeatureImage && secondaryFeature ? (
                          <div className="promotion-product-card__features">
                            <UniversalImage
                              wrapperClassName="promotion-product-card__icon"
                              img={secondaryFeatureImage}
                              imageAlt={secondaryFeatureImageAlt}
                            />
                            <span>{secondaryFeature}</span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="brochure">
                      <a href={fallbackUrl} className="brochure__btn">
                        <span>{pdfBrochure}</span>
                      </a>
                    </div>
                  </div>
                );
              });
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default PromotionProductCards;
