import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PromotionProductCards from 'components/PromotionProductCards';
import Layout from 'components/Layout';
import StoresList from 'components/StoresList';
import PromoInstructions from 'components/PromoInstructions';
import StoreFinder from 'components/StoreFinder';
import InView from 'components/InView';
import PromoInstructionBanner from 'components/PromoInstructionBanner';
import PromotionHeroBanner from 'components/PromotionHeroBanner';
import { IPromotionCampaignPageProps } from './models';

const PromotionCampaignPage: FC<IPromotionCampaignPageProps> = ({
  data: {
    promotionCampaignPage: {
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      ogImage,
      lang,
      promoInstructionSection,
      storesList,
      promotionProductCards,
      promotionHeroBanner,
      storeFinder,
    },
    salsifyProducts: { nodes: salsifyProducts },
    salsifyProductsVariants: { nodes: salsifyProductsVariants },
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  },
}) => {
  const { cards, sectionTitle: sectionTitleCards } = storesList[0];
  const {
    anchorId: promoInstructionSectionAnchorId,
    sectionTitle,
    descriptionBlock,
    image,
    imageAlt,
    instructions,
  } = promoInstructionSection[0];
  const heroBanner = promotionHeroBanner[0];

  return (
    <Layout
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
    >
      {promotionHeroBanner ? <PromotionHeroBanner {...heroBanner} /> : null}
      <PromoInstructionBanner
        sectionTitle={sectionTitle}
        descriptionBlock={descriptionBlock}
        image={image}
        imageAlt={imageAlt}
        anchorId={promoInstructionSectionAnchorId}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <PromoInstructions {...{ instructions }} />
          </Col>
        </Row>
      </Container>
      {storeFinder?.map(
        ({
          title,
          intro,
          places,
          useLocationCTALabel,
          initialMapCenter,
          initialMapZoom,
          storeFinderID,
          googleSearchButtonAriaLabel,
          googleRouteButton,
          googleMarkerYourLocation,
          googleErrorUserLocation,
          googleErrorGeocodingLocation,
          googleErrorMapLoading,
          buttonCloseAriaLabel,
        }) => (
          <div className="anchor" id={storeFinderID} key={title}>
            <InView>
              <StoreFinder
                {...{
                  title,
                  intro,
                  places,
                  useLocationCTALabel,
                  initialMapCenter,
                  initialMapZoom,
                  geocodingRegion: process.env.GATSBY_GOOGLE_MAPS_REGION,
                  storeFinderID,
                  googleSearchButtonAriaLabel,
                  googleRouteButton,
                  googleMarkerYourLocation,
                  googleErrorUserLocation,
                  googleErrorGeocodingLocation,
                  googleErrorMapLoading,
                  buttonCloseAriaLabel,
                }}
              />
            </InView>
          </div>
        )
      )}
      {cards ? <StoresList cards={cards} sectionTitle={sectionTitleCards} /> : null}
      <PromotionProductCards
        {...{ promotionProductCards, salsifyProducts, salsifyProductsVariants }}
      />
    </Layout>
  );
};

export const query = graphql`
  query($allSku: [String], $lang: String!, $pageIdRegex: String) {
    ...FragmentCommonCompositions
    promotionCampaignPage(lang: { eq: $lang }) {
      ...FragmentSeo
      storesList {
        ...FragmentStoresList
      }
      promoInstructionSection {
        ...FragmentPromoInstuctions
        ...FragmentPromoInstuctionBanner
      }
      promotionProductCards {
        ...TPromotionProductCardsFragment
      }
      promotionHeroBanner {
        ...FragmentPromotionHeroBanner
      }
      storeFinder {
        ...FragmentStoreFinder
      }
    }
    salsifyProductsVariants: allUmbracoProduct(
      filter: { productVariants: { elemMatch: { properties: { variantSku: { in: $allSku } } } } }
    ) {
      nodes {
        productVariants {
          properties {
            variantSku
            productTitle
            image {
              fallbackUrl
              gatsbyImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              mobileImage: gatsbyImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    salsifyProducts: allUmbracoProduct(filter: { sku: { in: $allSku } }) {
      nodes {
        defaultProductTitle
        sku
        defaultProductImage {
          fallbackUrl
          gatsbyImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          mobileImage: gatsbyImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    pageLangs: allPromotionCampaignPage(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
  }
`;

export default PromotionCampaignPage;
