import React, { useEffect } from 'react';

export type UseIntersectionObserverProps = {
  ref: React.RefObject<HTMLElement | undefined>;
  callback: () => void;
  observerOptions?: IntersectionObserverInit;
  triggerOnce?: boolean;
};

const useIntersectionObserver = ({
  ref,
  callback,
  observerOptions = {},
  triggerOnce = true,
}: UseIntersectionObserverProps): void => {
  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const observedElement = ref.current as Element;
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio === 1 && callback) {
          callback();

          if (triggerOnce) {
            observer.unobserve(observedElement);
          }
        }
      },
      {
        threshold: [1, 0],
        ...observerOptions,
      }
    );

    observer.observe(observedElement);

    return () => {
      observer.unobserve(observedElement);
    };
  }, [callback, observerOptions, ref]);
};

export default useIntersectionObserver;
