import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';

import { StoresListItemProps } from './model';

import './StoresListItem.scss';

const StoresListItem: FC<StoresListItemProps> = ({ logo, name, url, description }) => (
  <div className="stores-list__item" data-testid="stores-list-item">
    <a href={url} title={name} target="_blank" rel="noreferrer" className="stores-list__link">
      <div className="stores-list__slide">
        <div className="stores-list__image">
          <div className="stores-list__image-container">
            <UniversalImage img={logo} alt={name} className="stores-list__img" />
          </div>
          <DangerouslySetInnerHtml className="stores-list__button" html={name} />
        </div>
        <DangerouslySetInnerHtml html={description} className="stores-list__text" />
      </div>
    </a>
  </div>
);

export default StoresListItem;
