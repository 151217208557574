import React, { FC, useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import { Col, Row } from 'react-bootstrap';
import useStatus from 'hooks/useStatus';
import insertIf from 'utils/insertIf';
import { Location } from 'shared';
import {
  calculateDistance,
  prepareMarkerData,
  parseCoordinates,
  getUserLocationMarker,
} from './utils';
import { getCurrentPosition, googleGeocoding } from './services';
import PlacesList from './PlacesList';
import LocationSearch from './LocationSearch';
import Map, { mapStyles } from './Map';
import { StoreFinderProps } from './models';
import './StoreFinder.scss';

const StoreFinder: FC<StoreFinderProps> = ({
  places,
  title,
  intro,
  useLocationCTALabel,
  initialMapCenter,
  geocodingRegion,
  initialMapZoom = 10,
  googleSearchButtonAriaLabel,
  googleRouteButton,
  googleMarkerYourLocation,
  googleErrorUserLocation,
  googleErrorGeocodingLocation,
  googleErrorMapLoading,
  buttonCloseAriaLabel,
}) => {
  const [address, setAddress] = useState('');
  const [userLocation, setUserLocation] = useState<Location | null>(null);
  const [activePlaceLocation, setActivePlaceLocation] = useState<Location | null>(null);

  const { status, handleLoading, handleError, handleSuccess } = useStatus();
  const { locationToAddress, addressToLocation } = googleGeocoding(
    handleSuccess,
    handleError,
    googleErrorGeocodingLocation,
    geocodingRegion
  );

  const handleCurrentUserPosition = async () => {
    handleLoading();

    const position = await getCurrentPosition(handleError, googleErrorUserLocation);

    if (position) {
      setUserLocation(await locationToAddress(position));
    }
  };

  const onAddressSubmit = async (newAddress: string) => {
    handleLoading();
    setUserLocation(await addressToLocation(newAddress));
  };

  const sortedPlaces = useMemo(() => {
    if (!userLocation) {
      return places;
    }

    return places
      .map((place) => {
        const { lat, lng } = place;

        return {
          ...place,
          distance: calculateDistance(userLocation, { lat, lng }),
        };
      })
      .sort((a, b) => a.distance! - b.distance!);
  }, [userLocation, places]);

  const markers = useMemo(
    () => [
      ...insertIf(!!userLocation, getUserLocationMarker(userLocation)),
      ...sortedPlaces.map((place) =>
        prepareMarkerData({
          place,
        })
      ),
    ],
    [sortedPlaces, userLocation]
  );

  const onPlaceClick = async ({ lat, lng }: Location) => {
    if (!userLocation) {
      await handleCurrentUserPosition();
    }

    setActivePlaceLocation({ lat, lng });
  };

  const shouldRenderDirections = !!userLocation && !!activePlaceLocation;

  const center = useMemo(() => userLocation || parseCoordinates(initialMapCenter), [
    userLocation,
    initialMapCenter,
  ]);

  const onSearchInputReset = () => {
    setAddress('');
    setUserLocation(null);
    setActivePlaceLocation(null);
  };

  const onAddressChange = async (newAddress: string) => {
    setAddress(newAddress);
  };

  useEffect(() => {
    setAddress(userLocation?.formattedAddress || '');
  }, [userLocation]);

  return (
    <div className="store-finder" data-testid="store-finder">
      <Row className="no-gutters">
        <Col md="6" className="no-gutters">
          <div className="store-finder__box store-finder__box--list">
            <div className="store-finder__search">
              <LocationSearch
                {...{
                  title,
                  intro,
                  useLocationCTALabel,
                  onUseYourLocationClick: handleCurrentUserPosition,
                  onAddressSubmit,
                  status,
                  address,
                  onAddressChange,
                  onSearchInputReset,
                  googleSearchButtonAriaLabel,
                  buttonCloseAriaLabel,
                }}
              />
            </div>
            <div className="store-finder__places-list">
              <div className="store-finder__scrollable">
                <PlacesList
                  places={sortedPlaces}
                  onPlaceClick={onPlaceClick}
                  googleRouteButton={googleRouteButton}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col md="6" className="no-gutters">
          <div className="store-finder__box store-finder__box--map">
            <Map
              markers={markers}
              mapConfig={{
                mapContainerClassName: 'store-finder__map',
                id: 'store-finder-map',
                zoom: initialMapZoom,
                center,
                options: {
                  styles: mapStyles,
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                },
              }}
              googleMarkerYourLocation={googleMarkerYourLocation}
              googleErrorMapLoading={googleErrorMapLoading}
              {...(shouldRenderDirections && {
                directionsServiceOptions: {
                  destination: activePlaceLocation as Location,
                  origin: userLocation as Location,
                  travelMode: google.maps.TravelMode.WALKING,
                },
              })}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StoreFinder;

export const query = graphql`
  fragment FragmentStoreFinder on StoreFinder {
    places {
      id
      name
      address
      city
      zip
      lat
      lng
    }
    useLocationCTALabel
    title
    intro
    initialMapCenter
    initialMapZoom
    storeFinderID
    googleSearchButtonAriaLabel
    googleRouteButton
    googleMarkerYourLocation
    googleErrorUserLocation
    googleErrorGeocodingLocation
    googleErrorMapLoading
    buttonCloseAriaLabel
  }
`;
