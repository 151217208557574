import React, { FC } from 'react';
import cx from 'classnames';
import { LoadingProps } from './models';
import './Loading.scss';

const Loading: FC<LoadingProps> = ({ className, variant = 'light' }) => (
  <div
    className={cx(
      'loading',
      {
        'loading--light': variant.includes('light'),
        'loading--dark': variant.includes('dark'),
      },
      className
    )}
  />
);

export default Loading;
