import React, { FC } from 'react';
import { capitalize } from 'utils/stringUtils/capitalize';
import { formatDistance } from '../utils';
import { PlacesListProps } from './models';
import './PlacesList.scss';

const PlacesList: FC<PlacesListProps> = ({ places, onPlaceClick, googleRouteButton }) => (
  <div className="places-list" data-testid="places-list">
    {places.map(({ name, address, city, zip, id, distance, lat, lng }) => (
      <div className="places-list__item" key={id}>
        <div className="places-list__item-info">
          <p className="places-list__item-name">{name}</p>
          <p className="places-list__item-address">
            {`${zip} ${capitalize(city)}, ${capitalize(address)}`}
          </p>
          {typeof distance === 'number' ? (
            <p className="places-list__item-distance">{formatDistance(distance)}</p>
          ) : null}
        </div>
        <button
          className="nf-btn my-3"
          aria-label={googleRouteButton}
          onClick={() => onPlaceClick({ lat, lng })}
          type="button"
        >
          {googleRouteButton}
        </button>
      </div>
    ))}
  </div>
);

export default PlacesList;
