import { useState } from 'react';

export type Status = {
  value: 'loading' | 'error' | 'success' | undefined;
  message?: string;
};

const useStatus = (initialStatus?: Status) => {
  const [status, setStatus] = useState<Status>(
    initialStatus || {
      value: undefined,
    }
  );

  const handleLoading = () => {
    setStatus({
      value: 'loading',
    });
  };

  const handleError = (message: string) => {
    setStatus({
      value: 'error',
      message,
    });
  };

  const handleSuccess = () => {
    setStatus({
      value: 'success',
    });
  };

  return {
    status,
    handleSuccess,
    handleError,
    handleLoading,
  };
};

export default useStatus;
