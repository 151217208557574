import React, { FC } from 'react';
import { graphql } from 'gatsby';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';
import { Col, Row, Container } from 'react-bootstrap';

import { IPromoInstructionBannerProps } from './model';
import 'components/PromoInstructionBanner/PromoInstructionBanner.scss';

const PromoInstructionBanner: FC<IPromoInstructionBannerProps> = ({
  anchorId,
  descriptionBlock,
  image,
  imageAlt,
  sectionTitle,
}) => {
  const promoInstructionBannerImageProps = {
    wrapperClassName: 'promo-instruction-banner__image',
    img: image,
    imageAlt,
  };

  return (
    <div
      className="promo-instruction-banner anchor"
      data-testid="promo-instruction-banner"
      id={anchorId}
    >
      <Container fluid>
        <Row>
          <Col md={6} className="promo-instruction-banner__description-section">
            <h2 className="promo-instruction-banner__title">{sectionTitle}</h2>
            <DangerouslySetInnerHtml
              html={descriptionBlock}
              className="promo-instruction-banner__description"
            />
          </Col>
          <Col
            md={6}
            className="promo-instruction-banner__img"
            data-testid="promo-instruction-banner-img"
          >
            <UniversalImage {...promoInstructionBannerImageProps} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PromoInstructionBanner;

export const query = graphql`
  fragment FragmentPromoInstuctionBanner on PromoInstructionSection {
    anchorId
    descriptionBlock
    sectionTitle
    image {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    imageAlt
  }
`;
