import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import ControlledCarousel from 'components/ControlledCarousel';
import StoresListItem from './StoresListItem';

import responsiveObj from './constants';
import { StoresListProps } from './model';

import './StoresList.scss';

const StoresList: FC<StoresListProps> = ({ cards, sectionTitle }) => {
  const renderedSlides = cards.map(({ name, description, logo, url }) => (
    <StoresListItem key={url} name={name} description={description} logo={logo} url={url} />
  ));

  return (
    <div className="stores-list" data-testid="stores-list">
      <Container>
        <Row>
          <Col sm="12">
            <DangerouslySetInnerHtml className="stores-list__title" html={sectionTitle} />
          </Col>
        </Row>
      </Container>
      <ControlledCarousel
        classes="stores-list__wrapper"
        responsiveObj={responsiveObj}
        showDots
        customDotsClass="custom_dots"
        customContainerClass="stores-list__container"
      >
        {renderedSlides}
      </ControlledCarousel>
    </div>
  );
};

export default StoresList;

export const query = graphql`
  fragment FragmentStoresList on StoresList {
    sectionTitle
    cards {
      logo {
        fallbackUrl
        url
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 580, maxHeight: 260, fit: CONTAIN, background: "rgba(0,0,0,0)") {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      name
      url
      description
    }
  }
`;
