import React, { FC, useRef, useState } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { InViewProps } from './models';

const InView: FC<InViewProps> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const observerCallback = () => {
    setIsVisible(true);
  };

  const observerOptions = {
    threshold: 0,
  };

  useIntersectionObserver({ ref, callback: observerCallback, observerOptions });

  return <div ref={ref}>{isVisible ? children : null}</div>;
};

export default InView;
