import React, { FC } from 'react';
import { graphql } from 'gatsby';

import UniversalImage from 'components/common/UniversalImage';
import { IPromoInstructionsProps } from './model';

import './PromoInstructions.scss';

const PromoInstructions: FC<IPromoInstructionsProps> = ({ instructions }) => (
  <div className="promo-instructions__list" data-testid="promo-instructions">
    {instructions.map(({ description, image, imageAlt }) => (
      <div className="promo-instructions__item" key={description}>
        <UniversalImage
          imageAlt={imageAlt}
          img={image}
          className="promo-instructions__item-image"
        />
        <p className="promo-instructions__item-description">{description}</p>
      </div>
    ))}
  </div>
);

export default PromoInstructions;

export const query = graphql`
  fragment FragmentPromoInstuctions on PromoInstructionSection {
    instructions {
      description
      imageAlt
      image {
        fallbackUrl
        url
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        svg {
          content
        }
      }
    }
  }
`;
